<!--suppress LossyEncoding -->
<template>
  <div class="registrations h-full flex flex-col">
    <div class="registrations-info h-full px-3">
      <div class="hidden md:block rounded-2xl bg-white p-3 mt-3">
        <div class="button-group w-1/4	inline-flex text-gray-800 mr-5">
          <button @click="showWeek()" class="btn  flex-1 px-4 py-2 md:px-2 md:py-1 border-gray-400 border-2 border-r-0 rounded-l focus:outline-none" :class="selectedTime == 'week' ? 'btn-selected' : ''">WEEK</button>
          <button @click="showMonth()" class="btn flex-1 px-4 py-2 md:px-2 md:py-1 border-gray-400 border-2 border-l-0 border-r-0 focus:outline-none" :class="selectedTime == 'month' ? 'btn-selected' : ''">MAAND</button>
          <button @click="showYear()" class="btn flex-1 px-4 py-2 md:px-2 md:py-1 border-gray-400 border-2 border-l-0 rounded-r  focus:outline-none" :class="selectedTime == 'year' ? 'btn-selected' : ''">JAAR</button>
        </div>
        <div class="button-group w-1/4	inline-flex text-gray-800">
          <button @click="selectedView = 'accountTypes'; createGraph(); " class="btn  flex-1 px-4 py-2 md:px-2 md:py-1 border-gray-400 border-2 border-r-0 rounded-l focus:outline-none" :class="selectedView == 'accountTypes' ? 'btn-selected' : ''">TYPE</button>
          <button @click="selectedView = 'deviceTypes'; createGraph(); " class="btn flex-1 px-4 py-2 md:px-2 md:py-1 border-gray-400 border-2 border-l-0 border-r-0 focus:outline-none" :class="selectedView == 'deviceTypes' ? 'btn-selected' : ''">OS</button>
          <button @click="selectedView = 'numbersTypes'; createGraph(); " class="btn flex-1 px-4 py-2 md:px-2 md:py-1 border-gray-400 border-2 border-l-0 rounded-r focus:outline-none" :class="selectedView == 'numbersTypes' ? 'btn-selected' : ''">NUMMERS</button>
        </div>
      </div>
      <div class="graph h-full md:h-5/6 md:mt-3">
        <h1 @click="showDatePick = true" class="text-2xl text-gray-300">
          Aanmeldingen
          <span v-if="selectedTime == 'week'">{{'week ' + date}} {{selectedYear}}</span>
          <span v-if="selectedTime == 'month'">{{date}} {{selectedYear}}</span>
          <span v-if="selectedTime == 'year'">{{selectedYear}}</span>
        </h1>
        <vue-echarts :option="option" style="height: 90%" ref="chart" />
      </div>
    </div>
    <div class="white-area md:hidden rounded-tr-2xl rounded-tl-2xl bg-white p-3 pt-5 mt-auto">
      <div class="button-group inline-flex w-full text-gray-800 mb-3">
        <button @click="showWeek()" class="btn  flex-1 px-4 py-2 border-gray-400 border-2 border-r-0 rounded-l focus:outline-none" :class="selectedTime == 'week' ? 'btn-selected' : ''">WEEK</button>
        <button @click="showMonth()" class="btn flex-1 px-4 py-2 border-gray-400 border-2 border-l-0 border-r-0 focus:outline-none" :class="selectedTime == 'month' ? 'btn-selected' : ''">MAAND</button>
        <button @click="showYear()" class="btn flex-1 px-4 py-2 border-gray-400 border-2 border-l-0 rounded-r  focus:outline-none" :class="selectedTime == 'year' ? 'btn-selected' : ''">JAAR</button>
      </div>
      <div class="button-group inline-flex w-full text-gray-800">
        <button @click="selectedView = 'accountTypes'; createGraph(); " class="btn  flex-1 px-4 py-2 border-gray-400 border-2 border-r-0 rounded-l focus:outline-none" :class="selectedView == 'accountTypes' ? 'btn-selected' : ''">TYPE</button>
        <button @click="selectedView = 'deviceTypes'; createGraph(); " class="btn flex-1 px-4 py-2 border-gray-400 border-2 border-l-0 border-r-0 focus:outline-none" :class="selectedView == 'deviceTypes' ? 'btn-selected' : ''">OS</button>
        <button @click="selectedView = 'numbersTypes'; createGraph(); " class="btn flex-1 px-4 py-2 border-gray-400 border-2 border-l-0 rounded-r focus:outline-none" :class="selectedView == 'numbersTypes' ? 'btn-selected' : ''">NUMMERS</button>
      </div>
    </div>
    <div v-if="showDatePick" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div @click="showDatePick = false" class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                <!-- Heroicon name: outline/exclamation -->
                <svg class="h-6 w-6 dubline-text" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Aanmeldingen
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Kies een datum
                  </p>
                  <div class="relative inline-block w-full text-gray-700 mb-2">
                    <select class="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline" placeholder="Datum" v-model="selectedYear">
                      <option v-for="dateOptionsYear in dateOptionsYears" :key="dateOptionsYear.id" v-bind:value="dateOptionsYear.value">
                        {{ dateOptionsYear.text }}
                      </option>
                    </select>
                    <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                      <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                    </div>
                  </div>
                  <div v-if="selectedTime == 'month'" class="relative inline-block w-full text-gray-700">
                    <select class="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline" placeholder="Datum" v-model="selectedMonth">
                      <option v-for="dateOptionsMonth in dateOptionsMonths" :key="dateOptionsMonth.id" v-bind:value="dateOptionsMonth.value">
                          {{ dateOptionsMonth.text }}
                      </option>
                    </select>
                    <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                      <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                    </div>
                  </div>
                  <div v-if="selectedTime == 'week'" class="relative inline-block w-full text-gray-700">
                    <input class="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline" placeholder="1-52" type="text" name="week" v-model="selectedWeek">
                    <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="selectDate(selectedYear, selectedMonth, selectedWeek)" type="button" class="btn-selected w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm">
              Selecteer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEcharts } from 'vue3-echarts';
import moment from "moment";
moment.locale('nl');
export default {
    components: {
      VueEcharts
    },
    data() {
        return {
          showDatePick: false,
          titleDate: '',
          date: moment().isoWeek(),
          selectedTime: 'week',
          selectedView: 'deviceTypes',
          iosData: [],
          androidData: [],
          websiteData: [],
          geoData: [],
          nongeoData: [],
          mobileData: [],
          totalData: [],
          totalBusinessData: [],
          totalPrivateData: [],
          xData: [],
          selectedDate: moment(),
          selectedYear: '2022',
          selectedMonth: '',
          selectedWeek: '',
          // TODO make a function to fill in all the years from 2016
          dateOptionsYears: [
            { text: '2022', value: '2022' },
            { text: '2021', value: '2021' },
            { text: '2020', value: '2020' },
            { text: '2019', value: '2019' },
            { text: '2018', value: '2018' },
            { text: '2017', value: '2017' },
            { text: '2016', value: '2016' },
          ],
           dateOptionsMonths: [
            { text: 'Januari', value: '1' },
            { text: 'Februari', value: '2' },
            { text: 'Maart', value: '3' },
            { text: 'April', value: '4' },
            { text: 'Mei', value: '5' },
            { text: 'Juni', value: '6' },
            { text: 'Juli', value: '7' },
            { text: 'Augustus', value: '8' },
            { text: 'September', value: '9' },
            { text: 'Oktober', value: '10' },
            { text: 'november', value: '11' },
            { text: 'december', value: '12' },
          ],
          dateStep: 0,
          // this is needed for the initial load (read docs for improvement later)
          option: {
            animation: true,
            tooltip: {
              trigger: "axis",
              show: true,
            },
            legend: {
              show: true,
              bottom: "1%"
            },
            xAxis: {
              data: ["ma", "di", "wo", "do", "vr", "za", "zo"],
              nameTextStyle: {
                textBorderColor: "rgba(255, 0, 0, 1)",
                color: '#000'
              }
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                symbol: "circle",
                type: "line",
                // data: [11, 12, 20, 18, 14, 22, 25],
                symbolSize: 11,
                color: '#fdc602',
              }
            ],
            title: {
                textStyle: {
                fontSize: 24,
                color: '#fff'
              }
            },
            textStyle: {
              color: '#fff',
              textBorderColor: '#fff'
            },
          },
        }
    },

    methods: {
        showWeek()
        {
          this.selectedTime = 'week';
          this.date = this.selectedDate.isoWeek();
          // convert date to 2 dates first and last day of the week
          const firstday = this.selectedDate.isoWeekday(1).format('MM/DD/YYYY');
          const lastday = this.selectedDate.isoWeekday(7).format('MM/DD/YYYY');
          // clear the old graph data
          this.clearGraphData();
          // create object to send to api (provisioning api werkt alleen met multipart/form-data)
          let bodyFormData = new FormData();
          bodyFormData.append('beginDate', firstday);
          bodyFormData.append('endDate', lastday);
          bodyFormData.append('filter', 'day');
          this.$api.post('https://provisioning.dubline.nl/test/rocketeer/get_orders', bodyFormData)
          .then(response => {
              // loop over all registrations and fill in all the day values, using the key to determine which day it is monday-sunday
              let registrations = response.data;
              for (const [key, value] of Object.entries(registrations))
              {
                let day = moment(key, 'DD/MM/YYYY').day();
                if(day == 0)
                {
                  day = 7;
                }
                this.totalData[day - 1] = value.total;
                this.iosData[day - 1] = value.ios;
                this.androidData[day - 1] = value.android;
                this.websiteData[day - 1] = value.website;
                this.geoData[day - 1] = value.geo;
                this.nongeoData[day - 1] = value.nongeo,
                this.mobileData[day - 1] = value.mobile,
                this.totalBusinessData[day - 1] = value.business;
                this.totalPrivateData[day -1] = value.private;
              }
              this.xData = ["ma", "di", "wo", "do", "vr", "za", "zo"];
              // creates the graph, with the filled in information
              this.createGraph();
          })
          .catch(error => {
              console.log(error);
          })

        },
        showMonth()
        {
          this.selectedTime = 'month';
          this.date = this.selectedDate.format('MMMM');
          // convert date to 2 dates first and last day of the month
          const startOfMonth = this.selectedDate.clone().startOf('month');
          const endOfMonth   = this.selectedDate.clone().endOf('month');
          this.xData = [];
          // clear the old graph data
          this.clearGraphData();
          // create object to send to api (provisioning api werkt alleen met multipart/form-data)
          let bodyFormData = new FormData();
          bodyFormData.append('beginDate', startOfMonth.format('MM/DD/YYYY'));
          bodyFormData.append('endDate', endOfMonth.format('MM/DD/YYYY'));
          bodyFormData.append('filter', 'week');
          this.$api.post('https://provisioning.dubline.nl/test/rocketeer/get_orders', bodyFormData)
          .then(response => {
              // loop over all registrations and check what the first and lastweek is of the month, also fills in the values for the graph
              let registrations = response.data;
              console.log(registrations);
              let firstWeek = 0;
              let lastWeek = 0;
              let count = 0;
              for (const [key, value] of Object.entries(registrations))
              {
                console.log(key + ' and ' +  lastWeek)
                if(!firstWeek)
                {
                  console.log(('first week is ' + key))
                  firstWeek = key;
                }
                else if(key > lastWeek)
                {
                  console.log(key + ' is bigger then ' + lastWeek)
                  lastWeek = key;
                }

                this.totalData[count] = value.total;
                this.iosData[count] = value.ios;
                this.websiteData[count] = value.website;
                this.totalBusinessData[count] = value.business;
                this.totalPrivateData[count] = value.private;
                this.androidData[count++] = value.android;
                this.geoData[count] = value.geo;
                this.nongeoData[count] = value.nongeo,
                this.mobileData[count] = value.mobile
              }
              count = 0;
              // Loop over all weeks to fill the Xdata (some years have 53 weeks and it flows over into the next year. that's why we have the weekNum == 53 check)
              let weekNum = parseInt(startOfMonth.format('W'))
              for(let i = (weekNum == 53 ? 0 : weekNum); i <= parseInt(endOfMonth.format('W')); i++)
              {
                this.xData[count++] = i != 0 ? i : 53;
              }
              this.createGraph();
          })
          .catch(error => {
            console.log(error);
          })
        },
        showYear()
        {
          this.selectedTime = 'year';
          // convert date to 2 dates first and last day of the year
          const endOfYear   = this.selectedDate.clone().startOf('year').format('MM/DD/YYYY');
          const startOfYear = this.selectedDate.clone().endOf('year').format('MM/DD/YYYY');
          // since we are working with Quarters
          this.totalData = [0,0,0,0];
          this.iosData = [0,0,0,0];
          this.androidData = [0,0,0,0];
          this.websiteData = [0,0,0,0];
          this.geoData = [0,0,0,0];
          this.nongeoData = [0,0,0,0];
          this.mobileData = [0,0,0,0];
          this.totalBusinessData = [0,0,0,0];
          this.totalPrivateData = [0,0,0,0];
          // create object to send to api (provisioning api werkt alleen met multipart/form-data)
          let bodyFormData = new FormData();
          bodyFormData.append('beginDate', endOfYear);
          bodyFormData.append('endDate', startOfYear);
          bodyFormData.append('filter', 'year');
          this.$api.post('https://provisioning.dubline.nl/test/rocketeer/get_orders', bodyFormData)
          .then(response => {
            // loop over all the months and determine in which quarter they are 1 to 4
            let registrations = response.data;
            for (const [key, value] of Object.entries(registrations))
            {
              let date  = new Date(key);
              let month = date.getMonth();
              if(month >= 0 && month <= 2)
              {
                this.totalData[0] += value.total;
                this.iosData[0] += value.ios;
                this.androidData[0] += value.android;
                this.websiteData[0] += value.website;
                this.geoData[0] += value.geo;
                this.nongeoData[0] += value.nongeo;
                this.mobileData[0] += value.mobile;
                this.totalBusinessData[0] += value.business;
                this.totalPrivateData[0] += value.private;
              }
              if(month >= 3 && month <= 5)
              {
                this.totalData[1] += value.total;
                this.iosData[1] += value.ios;
                this.androidData[1] += value.android;
                this.websiteData[1] += value.website;
                this.geoData[1] += value.geo;
                this.nongeoData[1] += value.nongeo;
                this.mobileData[1] += value.mobile;
                this.totalBusinessData[1] += value.business;
                this.totalPrivateData[1] += value.private;
              }
              if(month >= 6 && month <= 8)
              {
                this.totalData[2] += value.total;
                this.iosData[2] += value.ios;
                this.androidData[2] += value.android;
                this.websiteData[2] += value.website;
                this.geoData[2] += value.geo;
                this.nongeoData[2] += value.nongeo;
                this.mobileData[2] += value.mobile;
                this.totalBusinessData[2] += value.business;
                this.totalPrivateData[2] += value.private;
              }
              if(month >= 9 && month <= 11)
              {
                this.totalData[3] += value.total;
                this.iosData[3] += value.ios;
                this.androidData[3] += value.android;
                this.websiteData[3] += value.website;
                this.geoData[3] += value.geo;
                this.nongeoData[3] += value.nongeo;
                this.mobileData[3] += value.mobile;
                this.totalBusinessData[3] += value.business;
                this.totalPrivateData[3] += value.private;
              }
            }
            // check later for better function
            for(let i = 0; i < 4; i++)
            {
                this.totalData[i] = this.totalData[i] ==  0 ? '-' : this.totalData[i]
                this.iosData[i] = this.iosData[i] ==  0 ? '-' : this.iosData[i]
                this.androidData[i] = this.androidData[i] ==  0 ? '-' : this.androidData[i]
                this.websiteData[i] = this.websiteData[i] ==  0 ? '-' : this.websiteData[i]
                this.geoData[i] = this.geoData[i] ==  0 ? '-' : this.geoData[i]
                this.nongeoData[i] = this.nongeoData[i] ==  0 ? '-' : this.nongeoData[i]
                this.mobileData[i] = this.mobileData[i] ==  0 ? '-' : this.mobileData[i]
                this.totalBusinessData[i] = this.totalBusinessData[i] ==  0 ? '-' : this.totalBusinessData[i]
                this.totalPrivateData[i] = this.totalPrivateData[i] ==  0 ? '-' : this.totalPrivateData[i]
            }
            this.xData = ['Q1', 'Q2', 'Q3', 'Q4'];
            this.createGraph();
          })
          .catch(error => {
            console.log(error);
          })
        },

        createGraph()
        {
          let series = [];
          if(this.selectedView == 'deviceTypes')
          {
            series = [
                {
                  name: 'Totaal',
                  data: this.totalData,
                  type: "line",
                  symbolSize: 11,
                  color: "#fdc602",
                  symbol: "circle",
                },
                {
                  name: 'Android',
                  data: this.androidData,
                  type: "line",
                  symbolSize: 11,
                  color: '#055862',
                  symbol: "circle",
                },
                {
                  name: 'iOS',
                  data: this.iosData,
                  type: "line",
                  symbolSize: 11,
                  color: '#f07d1b',
                  symbol: "circle",
                },
                {
                  name: 'Website',
                  data: this.websiteData,
                  type: "line",
                  symbolSize: 11,
                  color: '#EA5813',
                  symbol: "circle",
                }
              ]
          }
          else if(this.selectedView == 'accountTypes')
          {
            series = [
                {
                  name: 'Zakelijk',
                  data: this.totalBusinessData,
                  type: "line",
                  symbolSize: 11,
                  color: '#fdc602',
                  symbol: "circle",
                },
                {
                  name: 'Privé',
                  data: this.totalPrivateData,
                  type: "line",
                  symbolSize: 11,
                  color: '#055862',
                  symbol: "circle",
                }
              ]
          } else {
            series = [
              {
                name: 'Lokaal',
                data: this.geoData,
                type: "line",
                symbolSize: 11,
                color: '#fdc602',
                symbol: "circle",
              },
              {
                name: 'Nationaal',
                data: this.nongeoData,
                type: "line",
                symbolSize: 11,
                color: '#055862',
                symbol: "circle",
              },
              {
                name: 'Mobiel',
                data: this.mobileData,
                type: "line",
                symbolSize: 11,
                color: '#f07d1b',
                symbol: "circle",
              }
            ]
          }



          this.$refs.chart.setOption({
            animation: true,
            tooltip: {
              trigger: "axis",
              show: true,
            },
            legend: {
              show: true,
              bottom: "1%"
            },
            title: {
              textStyle: {
              fontSize: 24,
              color: '#fff'
              }
            },
            textStyle: {
              color: '#fff',
              textBorderColor: '#fff'
            },
            xAxis: {
              data: this.xData,
              nameTextStyle: {
                textBorderColor: "rgba(255, 0, 0, 1)",
                color: '#000'
            }
            },
            yAxis: {
              type: "value",
              name: this.selectedTime == 'month' ? 'Wk' : '',
              nameLocation: "start",
            },
              series: series,
          }, true)
        },

        selectDate(year, month, week)
        {
          if(this.selectedTime == 'year')
          {
            this.selectedDate = moment(year);
            this.showYear();
          }
          else if(this.selectedTime == 'month')
          {
            this.selectedDate = moment(year + '-' + month + '-1');
            console.log('month selected ' + this.selectedDate.format('MM/DD/YYYY'));
            this.showMonth();
          }
          else
          {
            this.selectedDate = moment().isoWeekYear(year).isoWeek(week).startOf('isoWeek')
            console.log('week selected ' + this.selectedDate.format('MM/DD/YYYY'));
            this.showWeek();
          }

          this.showDatePick = false;
          this.selectedDate = moment();
        },

        clearGraphData()
        {
          this.totalData =  [];
          this.iosData =  [];
          this.androidData =  [];
          this.websiteData = [];
          this.geoData = [];
          this.nongeoData = [];
          this.mobileData = [];
          this.totalBusinessData = [];
          this.totalPrivateData = [];
        }

    },

    mounted()
    {
      this.showWeek();
    }
}
</script>
